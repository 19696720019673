export const gastype = [
    {
        "id": 1,
        "name": "HFC-23"
    },
    {
        "id": 2,
        "name": "HFC-32"
    },
    {
        "id": 3,
        "name": "HFC-41"
    },
    {
        "id": 4,
        "name": "HFC-125"
    },
    {
        "id": 5,
        "name": "HFC-134"
    },
    {
        "id": 6,
        "name": "HFC-134a"
    },
    {
        "id": 7,
        "name": "HFC-143"
    },
    {
        "id": 8,
        "name": "HFC-143a"
    },
    {
        "id": 9,
        "name": "HFC-152a"
    },
    {
        "id": 10,
        "name": "HFC-227ea"
    },
    {
        "id": 11,
        "name": "HFC-236fa"
    },
    {
        "id": 12,
        "name": "HFC-245fa"
    },
    {
        "id": 13,
        "name": "HFC-43-I0mee"
    },
    {
        "id": 14,
        "name": "Perfluoromethane (PFC-14)"
    },
    {
        "id": 15,
        "name": "Perfluoroethane (PFC-116)"
    },
    {
        "id": 16,
        "name": "Perfluoropropane (PFC-218)"
    },
    {
        "id": 17,
        "name": "Perfluorocyclobutane (PFC-318)"
    },
    {
        "id": 18,
        "name": "Perfluorobutane (PFC-3-1-10)"
    },
    {
        "id": 19,
        "name": "Perfluoropentane (PFC-4-1-12)"
    },
    {
        "id": 20,
        "name": "Perfluorohexane (PFC-5-1-14)"
    },
    {
        "id": 21,
        "name": "PFC-9-1-18"
    },
    {
        "id": 22,
        "name": "Perfluorocyclopropane"
    },
    {
        "id": 23,
        "name": "Sulphur hexafluoride (SF6)"
    },
    {
        "id": 24,
        "name": "HFC-152"
    },
    {
        "id": 25,
        "name": "HFC-161"
    },
    {
        "id": 26,
        "name": "HFC-236cb"
    },
    {
        "id": 27,
        "name": "HFC-236ea"
    },
    {
        "id": 28,
        "name": "HFC-245ca"
    },
    {
        "id": 29,
        "name": "HFC-365mfc"
    },
    {
        "id": 30,
        "name": "Nitrogen trifluoride"
    },
    {
        "id": 31,
        "name": "R401A"
    },
    {
        "id": 32,
        "name": "R401B"
    },
    {
        "id": 33,
        "name": "R401C"
    },
    {
        "id": 34,
        "name": "R402A"
    },
    {
        "id": 35,
        "name": "R402B"
    },
    {
        "id": 36,
        "name": "R403A"
    },
    {
        "id": 37,
        "name": "R403B"
    },
    {
        "id": 38,
        "name": "R404A"
    },
    {
        "id": 39,
        "name": "R405A"
    },
    {
        "id": 40,
        "name": "R406A"
    },
    {
        "id": 41,
        "name": "R407A"
    },
    {
        "id": 42,
        "name": "R407B"
    },
    {
        "id": 43,
        "name": "R407C"
    },
    {
        "id": 44,
        "name": "R407D"
    },
    {
        "id": 45,
        "name": "R407E"
    },
    {
        "id": 46,
        "name": "R407F"
    },
    {
        "id": 47,
        "name": "R408A"
    },
    {
        "id": 48,
        "name": "R409A"
    },
    {
        "id": 49,
        "name": "R409B"
    },
    {
        "id": 50,
        "name": "R410A"
    },
    {
        "id": 51,
        "name": "R410B"
    },
    {
        "id": 52,
        "name": "R411A"
    },
    {
        "id": 53,
        "name": "R411B"
    },
    {
        "id": 54,
        "name": "R412A"
    },
    {
        "id": 55,
        "name": "R413A"
    },
    {
        "id": 56,
        "name": "R414A"
    },
    {
        "id": 57,
        "name": "R414B"
    },
    {
        "id": 58,
        "name": "R415A"
    },
    {
        "id": 59,
        "name": "R415B"
    },
    {
        "id": 60,
        "name": "R416A"
    },
    {
        "id": 61,
        "name": "R417A"
    },
    {
        "id": 62,
        "name": "R417B"
    },
    {
        "id": 63,
        "name": "R417C"
    },
    {
        "id": 64,
        "name": "R418A"
    },
    {
        "id": 65,
        "name": "R419A"
    },
    {
        "id": 66,
        "name": "R419B"
    },
    {
        "id": 67,
        "name": "R420A"
    },
    {
        "id": 68,
        "name": "R421A"
    },
    {
        "id": 69,
        "name": "R421B"
    },
    {
        "id": 70,
        "name": "R422A"
    },
    {
        "id": 71,
        "name": "R422B"
    },
    {
        "id": 72,
        "name": "R422C"
    },
    {
        "id": 73,
        "name": "R422D"
    },
    {
        "id": 74,
        "name": "R422E"
    },
    {
        "id": 75,
        "name": "R423A"
    },
    {
        "id": 76,
        "name": "R424A"
    },
    {
        "id": 77,
        "name": "R425A"
    },
    {
        "id": 78,
        "name": "R426A"
    },
    {
        "id": 79,
        "name": "R427A"
    },
    {
        "id": 80,
        "name": "R428A"
    },
    {
        "id": 81,
        "name": "R429A"
    },
    {
        "id": 82,
        "name": "R430A"
    },
    {
        "id": 83,
        "name": "R431A"
    },
    {
        "id": 84,
        "name": "R432A"
    },
    {
        "id": 85,
        "name": "R433A"
    },
    {
        "id": 86,
        "name": "R433B"
    },
    {
        "id": 87,
        "name": "R433C"
    },
    {
        "id": 88,
        "name": "R434A"
    },
    {
        "id": 89,
        "name": "R435A"
    },
    {
        "id": 90,
        "name": "R436A"
    },
    {
        "id": 91,
        "name": "R436B"
    },
    {
        "id": 92,
        "name": "R437A"
    },
    {
        "id": 93,
        "name": "R438A"
    },
    {
        "id": 94,
        "name": "R439A"
    },
    {
        "id": 95,
        "name": "R440A"
    },
    {
        "id": 96,
        "name": "R441A"
    },
    {
        "id": 97,
        "name": "R442A"
    },
    {
        "id": 98,
        "name": "R443A"
    },
    {
        "id": 99,
        "name": "R444A"
    },
    {
        "id": 100,
        "name": "R445A"
    },
    {
        "id": 101,
        "name": "R500"
    },
    {
        "id": 102,
        "name": "R501"
    },
    {
        "id": 103,
        "name": "R502"
    },
    {
        "id": 104,
        "name": "R503"
    },
    {
        "id": 105,
        "name": "R504"
    },
    {
        "id": 106,
        "name": "R505"
    },
    {
        "id": 107,
        "name": "R506"
    },
    {
        "id": 108,
        "name": "R507A"
    },
    {
        "id": 109,
        "name": "R508A"
    },
    {
        "id": 110,
        "name": "R508B"
    },
    {
        "id": 111,
        "name": "R509A"
    },
    {
        "id": 112,
        "name": "R510A"
    },
    {
        "id": 113,
        "name": "R511A"
    },
    {
        "id": 114,
        "name": "R512A"
    },
    {
        "id": 115,
        "name": "CFC-11/R11 = trichlorofluoromethane"
    },
    {
        "id": 116,
        "name": "CFC-12/R12 = dichlorodifluoromethane"
    },
    {
        "id": 117,
        "name": "CFC-13"
    },
    {
        "id": 118,
        "name": "CFC-113"
    },
    {
        "id": 119,
        "name": "CFC-114"
    },
    {
        "id": 120,
        "name": "CFC-115"
    },
    {
        "id": 121,
        "name": "Halon-1211"
    },
    {
        "id": 122,
        "name": "Halon-1301"
    },
    {
        "id": 123,
        "name": "Halon-2402"
    },
    {
        "id": 124,
        "name": "Carbon tetrachloride"
    },
    {
        "id": 125,
        "name": "Methyl bromide"
    },
    {
        "id": 126,
        "name": "Methyl chloroform"
    },
    {
        "id": 127,
        "name": "HCFC-22/R22 = chlorodifluoromethane"
    },
    {
        "id": 128,
        "name": "HCFC-123"
    },
    {
        "id": 129,
        "name": "HCFC-124"
    },
    {
        "id": 130,
        "name": "HCFC-141b"
    },
    {
        "id": 131,
        "name": "HCFC-142b"
    },
    {
        "id": 132,
        "name": "HCFC-225ca"
    },
    {
        "id": 133,
        "name": "HCFC-225cb"
    },
    {
        "id": 134,
        "name": "HCFC-21"
    },
    {
        "id": 135,
        "name": "HFE-125"
    },
    {
        "id": 136,
        "name": "HFE-134"
    },
    {
        "id": 137,
        "name": "HFE-143a"
    },
    {
        "id": 138,
        "name": "HCFE-235da2"
    },
    {
        "id": 139,
        "name": "HFE-245cb2"
    },
    {
        "id": 140,
        "name": "HFE-245fa2"
    },
    {
        "id": 141,
        "name": "HFE-254cb2"
    },
    {
        "id": 142,
        "name": "HFE-347mcc3"
    },
    {
        "id": 143,
        "name": "HFE-347pcf2"
    },
    {
        "id": 144,
        "name": "HFE-356pcc3"
    },
    {
        "id": 145,
        "name": "HFE-449sl (HFE-7100)"
    },
    {
        "id": 146,
        "name": "HFE-569sf2 (HFE-7200)"
    },
    {
        "id": 147,
        "name": "HFE-43-10pccc124 (H-Galden1040x)"
    },
    {
        "id": 148,
        "name": "HFE-236ca12 (HG-10)"
    },
    {
        "id": 149,
        "name": "HFE-338pcc13 (HG-01)"
    },
    {
        "id": 150,
        "name": "Trifluoromethyl sulphur pentafluoride"
    },
    {
        "id": 151,
        "name": "PFPMIE"
    },
    {
        "id": 152,
        "name": "Dimethylether"
    },
    {
        "id": 153,
        "name": "Methylene chloride"
    },
    {
        "id": 154,
        "name": "Methyl chloride"
    },
    {
        "id": 155,
        "name": "R290 = propane"
    },
    {
        "id": 156,
        "name": "R600A = isobutane"
    },
    {
        "id": 157,
        "name": "R600 = butane"
    },
    {
        "id": 158,
        "name": "R601 = pentane"
    },
    {
        "id": 159,
        "name": "R601A = isopentane"
    },
    {
        "id": 160,
        "name": "R170 = ethane"
    },
    {
        "id": 161,
        "name": "R1270 = propene"
    },
    {
        "id": 162,
        "name": "R1234yf*"
    },
    {
        "id": 163,
        "name": "R1234ze*"
    }
]