import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
const { DateTime } = require('luxon')


const QuantitativeApproverOverdue = () => {

    const login_data = useSelector((state) => state.user.userdetail)
    const navigate = useHistory()
    const [submittedQuantitative, setSubmittedQuantitative] = useState([])
    const [rawsitelist, setRawSitelist] = useState([])
    const [dcflist, setDcfList] = useState([])
    const [load, setLoading] = useState(true)
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }


        const promise0 = APIServices.get(API.DCF)
        const promise1 = APIServices.get(API.QN_Submit_UP(login_data.information.cid))
        const promise2 = APIServices.get(API.LocationOne_UP(login_data.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(API.DCF_Entity_UP(login_data.information.cid))
        const promise4 = APIServices.get(API.DF_Entity_UP(login_data.information.cid))
        const promise5 = APIServices.get(API.SRF_Entity_UP(login_data.information.cid))
        const promise6 = APIServices.get(API.DCF_Entity_User_UP(login_data.information.cid))
        const promise7 = APIServices.get(API.DF_Entity_User_UP(login_data.information.cid))
        const promise8 = APIServices.get(API.SRF_Entity_User_UP(login_data.information.cid))

        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8]).then((values) => {
            let quantitative_submitted = values[1].data, qn_submit_list = [], dcf_list = values[0].data, quantitative_ass_list = values[6].data, qualitative_ass_list = values[7].data, srf_ass_list = values[8].data
            let quantitative_entity_list = values[3].data, qualitative_entity_list = values[4].data, srf_entity_list = values[5].data
            const shapedSite = values[2].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            let filtered_qn_ass = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && checkEnity(i, quantitative_entity_list, shapedSite, 'dcfId'))
            setDcfList(dcf_list)
            setRawSitelist(shapedSite)
            quantitative_submitted.forEach((item) => {
                let filtered_qn_ass_index = filtered_qn_ass.findIndex(i => { console.log(i.id, item.entityUserAssId, i.entityAssId, item.entityAssId); return (i.id === item.entityUserAssId && i.entityAssId === item.entityAssId) })
                if (filtered_qn_ass_index !== -1) {
                    if (filtered_qn_ass[filtered_qn_ass_index].approver_ids.includes(login_data.id)) {
                        qn_submit_list.push(item)
                    }
                }
            })
            setSubmittedQuantitative(qn_submit_list.filter(i => i.type === 2))
            setLoading(false)
        })

    }, [])

    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex(k => k[obj] === rowData[obj])
        if (index !== -1) {
            let entity = entity_list[index]
            console.log(entity)
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0)
            } else if (rowData.level === 1) {
                return entity.tier1_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 2) {
                return entity.tier2_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 3) {
                return entity.tier3_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            }
        } else {
            return false
        }

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const titleTemplate = (rowData) => {
        let text = 'Not Found'
        let dcf_index = dcflist.findIndex(i => i.id === rowData.dcfId)
        if (dcf_index !== -1) {
            text = dcflist[dcf_index].title
        }
        return (<div className="text-underline clr-navy fw-5" onClick={() => { navigate.push({ pathname: '/data_input_approver/' + rowData.dcfId + '/' + rowData.id }) }}>{text}</div>)
    }
    const coverageTemplate = (rowData) => {
        return (<>
            {getCoverageText(rowData, rawsitelist)}
        </>)
    }
    const getUser = (uid) => {
        return 'NA'
    }
    const submitterTemplate = (rowData) => {

        return (<>
            {getUser(rowData.reporter_modified_by)}
        </>)
    }
    const submittedTemplate = (rowData) => {

        return (<>
            {DateTime.fromISO(rowData.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')}
        </>)
    }
    const rpFrequencyTemplate = (rowData) => {

        return <spna>{getFrequencyText(rowData.frequency)}</spna>
    }
    const getFrequencyText = (id) => {

        return frequency_list.find(i => { return i.id === id }).name
    }
    const sortRP = (e) => {
        console.log(e.data)
        if (e.order === 1) {
            return e.data.sort((a, b) => {

                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, 'MMM-yyyy');
                let dateB = DateTime.fromFormat(b.reporting_period, 'MMM-yyyy');
                if (a.reporting_period.includes('to')) {

                    dateA = DateTime.fromFormat(a.reporting_period.split('to')[0].trim(), 'MMM-yyyy');

                }
                if (b.reporting_period.includes('to')) {
                    dateB = DateTime.fromFormat(b.reporting_period.split('to')[0].trim(), 'MMM-yyyy');
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }


    }
    const getStatusText = (val, rowData) => {
        let text = 'NOT SUBMITTED'
        console.log(rowData)
        if (rowData !== undefined) {
            if (rowData.type === 1) {

                text = 'Not Reviewed'

            } else if (rowData.type === 2) {

                if (rowData.self) {
                    text = 'Self Reviewed'
                } else {
                    text = 'Reviewed'
                }


            } else if (rowData.type === 3) {

                text = 'APPROVED'

            }
        }
        return text
    }
    const sortStatus = (e) => {

        if (e.order === 1) {
            return e.data.sort((a, b) => {
                const dateA = getStatusText('', a)
                const dateB = getStatusText('', b)
                console.log(dateA, dateB)
                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

        } else {
            return e.data.sort((a, b) => {
                const dateA = getStatusText('', a)
                const dateB = getStatusText('', b)

                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            })
        }
    }
    const statusTemplate = (rowData) => {

        let type = 0
        let text = 'NOT SUBMITTED'
        let classtext = "status-tag-gray"
        if (rowData !== undefined) {
            if (rowData.type === 0) {
                if (rowData.reject === 1) {
                    type = 1
                    text = 'RETURNED'
                    classtext = "status-tag-red"
                } else {
                    type = 2
                    text = 'DRAFT'
                    classtext = "status-tag-orange"
                }
            } else if (rowData.type === 1) {
                if (rowData.reject === 1) {
                    type = 1
                    text = 'RETURNED'
                    classtext = "status-tag-red"
                } else {
                    type = 3
                    text = 'SUBMITTED'
                    classtext = "status-tag-green"
                }

            } else if (rowData.type === 2) {
                type = 3
                if (rowData.self) {
                    text = 'Self Reviewed'
                } else {
                    text = 'Reviewed'
                }

                classtext = "status-tag-green"
            } else if (rowData.type === 3) {
                type = 4
                text = 'APPROVED'
                classtext = "status-tag-green"
            }
        }
        return (
            <Tag style={{ padding: '4px 8px' }} className={classtext}>{text}</Tag>
        )
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >
                <div>
                    <div className="col-12 p-5" style={{
                        justifyContent: 'center'
                    }}>
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start">   Historic  Approvals ({submittedQuantitative.length})  </label>

                    </div>
                    {
                        <DataTable loading={load} value={submittedQuantitative} scrollable paginator rows={20} >
                            <Column header='Description' body={titleTemplate}  ></Column>
                            <Column header='Coverage' body={coverageTemplate}  ></Column>
                            <Column field="frequency" header='Reporting Frequency' body={rpFrequencyTemplate} />
                            <Column sortable sortFunction={sortRP} header='Reporting Period' field="reporting_period" />
                            <Column sortable sortFunction={sortStatus} field="xyz" header='Status' body={statusTemplate} />
                            <Column header='Reviewed By' field="reporter_modified_by" body={submitterTemplate} />
                            <Column header='Reviewed On' field="reporter_modified_by" body={submittedTemplate} />
                        </DataTable>

                    }
                </div>
            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeApproverOverdue, comparisonFn);