export const purchase_gs = [
    {
        "name": "Farms"
    },
    {
        "name": "Forestry, fishing and related activities"
    },
    {
        "name": "Oil and gas extraction"
    },
    {
        "name": "Mining, except oil and gas"
    },
    {
        "name": "Support activities for mining"
    },
    {
        "name": "Utilities"
    },
    {
        "name": "Construction"
    },
    {
        "name": "Food and beerage and tobacco products"
    },
    {
        "name": "Textile mills and textile product mills"
    },
    {
        "name": "Apparel and leather and allied products"
    },
    {
        "name": "Wood products"
    },
    {
        "name": "Paper products"
    },
    {
        "name": "Printing and related support actiivites"
    },
    {
        "name": "Petroleum and coal products"
    },
    {
        "name": "Chemical products"
    },
    {
        "name": "Plastics and rubber products"
    },
    {
        "name": "Monmetallic mineral products"
    },
    {
        "name": "Primary metals"
    },
    {
        "name": "Fabricated metal products"
    },
    {
        "name": "Machinery"
    },
    {
        "name": "Computer and electronic products"
    },
    {
        "name": "Electrical equipment, appliances, and components"
    },
    {
        "name": "Motor vehicles, bodies and trailers, and parts"
    },
    {
        "name": "Other transportation equipment"
    },
    {
        "name": "Furniture and related products"
    },
    {
        "name": "Miscellaneous manufacturing"
    },
    {
        "name": "Wholesale trade"
    },
    {
        "name": "Motor vehicle and parts dealers"
    },
    {
        "name": "Food and beverage stores"
    },
    {
        "name": "General merchandise stores"
    },
    {
        "name": "Air transportation"
    },
    {
        "name": "Rail transportation"
    },
    {
        "name": "Water transportation"
    },
    {
        "name": "Truck transportation"
    },
    {
        "name": "Transit and ground passenger transportation"
    },
    {
        "name": "Pipeline transportation"
    },
    {
        "name": "Other transportation and support activities"
    },
    {
        "name": "Warehousing and storage"
    },
    {
        "name": "Other retail"
    },
    {
        "name": "Publishing industries, except internet (includes software)"
    },
    {
        "name": "Motion picture and sound recording industries"
    },
    {
        "name": "Broadcasting and telecommunications"
    },
    {
        "name": "Data processing, internet publishing, and other information services"
    },
    {
        "name": "Federal Reserve banks, credit intermediation, and related activities"
    },
    {
        "name": "Securities, commodity contracts, and investments"
    },
    {
        "name": "Insurance carriers and related activities"
    },
    {
        "name": "Funds, trusts, and other financial vehicles"
    },
    {
        "name": "Rental and leasing services and lessors of intangible assets"
    },
    {
        "name": "Legal services"
    },
    {
        "name": "Miscellaneous professional, scientific, and technical services"
    },
    {
        "name": "Computer systems design and related services"
    },
    {
        "name": "Management of companies and enterprises"
    },
    {
        "name": "Administrative and support services"
    },
    {
        "name": "Waste management and remediation services"
    },
    {
        "name": "Educational services"
    },
    {
        "name": "Ambulatory health care services"
    },
    {
        "name": "Hospitals"
    },
    {
        "name": "Nursing and residential care facilities"
    },
    {
        "name": "Social assistance"
    },
    {
        "name": "Performing arts, spectator sports, museums, and related activities"
    },
    {
        "name": "Amusements, gambling, and recreation industries"
    },
    {
        "name": "Accommodation"
    },
    {
        "name": "Food services and drinking places"
    },
    {
        "name": "Other services, except government"
    },
    {
        "name": "Housing"
    },
    {
        "name": "Other real estate"
    }
]