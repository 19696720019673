import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { Dropdown } from "primereact/dropdown";
import { EditText } from "react-edit-text";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { CascadeSelect } from "primereact/cascadeselect";
import Swal from "sweetalert2";
import { Checkbox } from "primereact/checkbox";
import $ from "jquery";
import { API } from "../../constants/api_url";
import { ContextMenu } from 'primereact/contextmenu';
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from 'primereact/inputtextarea'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Tag } from "primereact/tag";
import { BP8EQ2, BP2LQ3, BP2LQ5, Stationary_Combustion_, Fugitive_, Mobile_Combustion_, Purchase_Goods_Services_, GR2LQ1, GR2LQ2, GR2LQ3, Business_Travel_, HotelStay, Electricity, Employee_Category, Diversity_of_Employees, Capital_Goods, Scope3_Investments, Upstream_Trans_Dist, Downstream_Trans_Dist, Employee_Category_Diversity_STT, Total_No_of_New_Employee_STT, Total_No_of_Employee_Left_STT, Total_No_of_Employee_Hire_TurnOver_STT, Employee_Training_Hours_STT } from "../../components/hardcoded/hardcodedforms";
import { Editor } from "primereact/editor";
import { hardcoded } from "../../components/hardcoded/hardcodedid";
import APIServices from "../../service/APIService";
import { DateTime } from "luxon";
import { StickyContainer, Sticky } from 'react-sticky';
import { DCFSubmissionReturn, DCFSubmission } from "../../constants/emailTemplate";
import { InputNumber } from "primereact/inputnumber";
import { AttachmentComponent } from "../../components/Forms/Attachment";
window.jQuery = $;
window.$ = $;

const OverallQnSubmissionPreview = () => {
    const selector = useSelector((state) => state.user.userdetail);
    const [data, setData] = useState([])
    const [response, setResponse] = useState([])
    const [std, setStd] = useState(null)
    const [document, setDocument] = useState(null)
    const [actual, setActual] = useState([])
    const [show, setShow] = useState(false)
    const [msgtype, setMsgType] = useState(0)
    const [sitelist, setSiteList] = useState([])
    const navigate = useHistory()

    const forceUpdate = useForceUpdate();
    const { id, id2 } = useParams();
    const params = useLocation()

    const userList = useSelector(state => state.userlist.userList)
    const [ass, setAss] = useState([])
    // const hardcodeddcf = ['10', '11', '36', '15', '16', '188', '195', '196','245']
    useEffect(async () => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }



        const promise0 = APIServices.get(API.DCF_Edit(id))
        const promise1 = APIServices.get(API.QN_Submission_Edit(id2))
        const promise2 = APIServices.get(API.LocationOne_UP(selector.information.cid) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(API.DCF_Entity_User_UP(selector.information.cid))
        const promise4 = APIServices.get(API.DCF_Entity_UP(selector.information.cid))
        Promise.all([promise0, promise1, promise2, promise3, promise4]).then((values) => {
            let form = JSON.parse(JSON.stringify(values[0].data))

            let submission = JSON.parse(JSON.stringify(values[1].data))
            let resObj = { ...submission }
            if (resObj.response.length) {
                form.data1 = resObj.response
            }

            const shapedSite = values[2].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            setSiteList(shapedSite)
            let filtered_qn_ass = values[3].data.filter((i) => checkEnity(i, values[4].data, shapedSite, 'dcfId'))
            let ass_index = filtered_qn_ass.findIndex(i => i.entityAssId === resObj.entityAssId && i.id === resObj.entityUserAssId)
            console.log(ass_index, filtered_qn_ass[ass_index])
            if (ass_index !== -1 && (filtered_qn_ass[ass_index].reporter_ids.includes(selector.id) || filtered_qn_ass[ass_index].reviewer_ids.includes(selector.id))) {

                console.log(resObj, form)
                setDocument(resObj.documents === undefined ? null : resObj.documents)
                setStd(resObj.standard)
                setShow(true)
                setData(prevData => ({ ...prevData, ...form }));
                setTimeout(() => {
                    setResponse(prevResponse => ({ ...prevResponse, ...resObj }));
                }, 500)

                setMsgType(resObj.type)

            }



        }).catch((e) => {

            setShow(false)
        })

    }, []);

    useEffect(() => {
        console.log(response, data)
    }, [response, data])

    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex(k => k[obj] === rowData[obj])
        if (index !== -1) {
            let entity = entity_list[index]
            console.log(entity)
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0)
            } else if (rowData.level === 1) {
                return entity.tier1_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 2) {
                return entity.tier2_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 3) {
                return entity.tier3_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            }
        } else {
            return false
        }

    }
    const checkUpdation = () => {
        let a = JSON.stringify(data.data1)
        let b = actual
        console.log(a === b)
        return a === b

    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                console.log(item);
                const startDate = DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
                const endDate = DateTime.fromFormat(item[item.length - 1], 'MM-yyyy').toFormat('LLL-yyyy');
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], 'MM-yyyy').toFormat('LLL-yyyy');
            }
        }
    };
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }

    function getMinMaxDates(dateStrings) {
        // Parse date strings and sort them
        const dates = dateStrings.map(dateStr => DateTime.fromFormat(dateStr, 'MM-yyyy')).sort((a, b) => a - b);

        // Get min and max dates
        const minDate = dates[0].startOf('month').toJSDate();
        const maxDate = dates[dates.length - 1].endOf('month').toJSDate();

        return {
            minDate,
            maxDate
        };
    }

    const onCheckBoxSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = !items.selected
            }
        })
        forceUpdate()
    }
    const onRadioButtonSelected = (item, cbind) => {
        console.log(item)
        item.values.map((items, ind) => {
            if (ind === cbind) {

                items.selected = true
            } else {
                items.selected = false
            }
        })
        forceUpdate()
    }
    const onDateSelected = (item, val) => {

        item.value = val;
        forceUpdate()
    }
    const onNumberChange = (item, val, nan) => {
        if (nan !== undefined) {
            if (isNaN(val)) {
                item.value = undefined
            } else {
                item.value = val;
            }

        } else {
            item.value = val;
        }

        forceUpdate()
    }

    const onChangeDropwdown = (item, val) => {
        item.value = val;
        console.log(val)
        item.values.forEach((i) => {
            if (i.value === val) {
                i.selected = true
            } else {
                i.selected = false
            }
        })
        forceUpdate()
    }
    const renderItems = (item, index) => {


        if (item.type === 'checkbox-group') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 text-justify fs-16 fw-5'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i>} </label>
                    <div className="col-5">
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="flex text-justify fs-14 fw-5" style={{ marginBottom: 10 }}>
                                    <Checkbox disabled inputId={"cb" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => { onCheckBoxSelected(item, cbind) }} checked={cb.selected} />
                                    <label htmlFor={"cb" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'date') {

            return (
                <div className="flex flex-wrap  gap-3  grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'> {item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}
                        {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <Calendar disabled placeholder={item.placeholder} className="col-5 fs-14 fw-4" value={(item.value !== null && item.value !== undefined) ? moment(item.value).toDate() : null} onChange={(e) => { onDateSelected(item, e.value) }} />
                </div>
            )
        } else if (item.type === 'number') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputNumber disabled min={0} placeholder={item.placeholder} maxFractionDigits={8} onWheel={(e) => e.target.blur()} keyfilter="num" style={{ width: '100%' }} value={item.value} onChange={(e) => { onNumberChange(item, e.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'paragraph') {
            return (
                <div className="flex flex-wrap  gap-3 fs-16 fw-5 text-justify justify-content-center" style={{ padding: 10 }}>

                    <label >{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')}</label>

                </div>
            )
        } else if (item.type === 'radio-group') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{

                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 grid" style={{ padding: 10 }} >
                        {item.values.map((cb, cbind) => {
                            return (
                                <div className="p-2 flex text-justify fs-14 fw-5 align-items-center" >
                                    <RadioButton disabled inputId={"rg" + index + cbind} name={cb.label} value={cb.value} onChange={(e) => onRadioButtonSelected(item, cbind)} checked={cb.selected === true} />

                                    <label htmlFor={"rg" + index + cbind} className="ml-2">{cb.label}</label>
                                </div>
                            )
                        })

                        }
                    </div>

                </div>
            )
        } else if (item.type === 'select') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>


                    <div className="col-5 fw-4 fs-14">
                        <Dropdown disabled placeholder={item.placeholder} options={item.values} style={{ width: '100%' }} optionLabel='label' optionValue="value" value={item.value} onChange={(e) => { onChangeDropwdown(item, e.value) }} />
                    </div>

                </div>
            )
        } else if (item.type === 'text') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}


                        > help</i></span>} </label>
                    <div className="col-5 fs-14 fw-4" >
                        <InputText disabled style={{ width: '100%' }} value={item.value} placeholder={item.placeholder} onChange={(e) => { onNumberChange(item, e.target.value) }} />

                    </div>
                </div>
            )
        } else if (item.type === 'textarea') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} position='top' />
                    <label className='col-5 fs-16 fw-5 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}

                        > help</i></span>} </label>
                    <div className="col-5 " >

                        {item.value && <div dangerouslySetInnerHTML={{ __html: item.value }} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }}> </div>}
                    </div>

                </div>
            )
        } else if (item.type === 'file') {
            return (
                <div className="flex flex-wrap  gap-3 grid" style={{ marginBottom: 15, padding: 10, border: item.error === 1 && '1px solid red' }}>
                    <Tooltip target={".tooltip" + index} />
                    <label style={{ display: 'flex' }} className='col-5 fw-5 fs-16 text-justify'>{item.label.replace(/(<([^>]+)>)/gi, "")
                        .replace(/\n/g, " ")
                        .replace(/&nbsp;/g, " ").replace('&amp;', '&')} {item.description !== undefined && item.description.trim().length !== 0 && <span><i style={{
                            fontSize: '18px',
                            marginLeft: '5px'
                        }} className={`material-icons fs-14 tooltip` + index} data-pr-tooltip={item.description}
                            data-pr-position="right"
                            data-pr-at="right+5 top"
                            data-pr-my="left center-2"> help</i></span>} </label>
                    <div className="col-5" >

                        {item.value !== undefined && item.value !== null && item.value.length !== 0 &&
                            <div className="col-12" style={{
                                maxHeight: 300,
                                overflow: 'auto',
                                border: '1px solid #ced4da'
                            }} >
                                <div style={{
                                    border: '1px solid #6366F170',
                                    borderRadius: '10px'
                                }}>
                                    {item.value.map((file, findex) => {

                                        return (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center', margin: 5
                                                }} >
                                                    <div className="flex align-items-center " style={{ width: '60%' }}>
                                                        {(file.extension === '.pdf' || file.extension === '.PDF') ?
                                                            <div>
                                                                <iframe src={API.Docs + file.originalname} /> </div> :
                                                            <img alt={file.originalname} role="presentation" src={API.Docs + file.originalname} width={100} style={{ borderRadius: 10 }} />}
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.originalname}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={'View'} onClick={() => { window.open(API.Docs + file.originalname) }} style={{ width: '20%' }} severity="warning" className="px-3 py-2" />
                                                    <Button type="button" icon="pi pi-times" style={{ marginRight: 10 }} className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => removeImage(index, findex)} />
                                                </div>

                                            </>
                                        )
                                    })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
        }

    }
    const handleFileUpload = (e, item) => {
        let ext = e.target.files[0].name.substr(e.target.files[0].name.lastIndexOf('.'))
        let allowedext = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.pdf', '.PDF']
        if (allowedext.includes(ext)) {
            let formData = new FormData()
            formData.append('file', e.target.files[0])
            APIServices.post(API.FilesUpload, formData, {
                headers: {
                    'content-type': 'multipart/form-data'

                }
            }).then((res) => {
                res.data.files[0].extension = ext
                if (item.value === undefined) {
                    item['value'] = [res.data.files[0]]
                } else {
                    if (item.multiple) {
                        item['value'].push(res.data.files[0])
                    } else {
                        item['value'] = [res.data.files[0]]
                    }

                }
                forceUpdate()
            })
        } else {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "invalid file format, supported format JPEG,PNG & PDF only",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }
    const resetFiles = (item, index) => {

        item.value = []
        forceUpdate()

    }
    const removeImage = (index, findex) => {

        data.data1[index].value.splice(findex, 1)
        forceUpdate()

    }

    const checkResponse = () => {
        let result = 0
        let total = data.data1.filter((i) => { return i.required === true }).length

        data.data1.forEach((item) => {


            if (item.type === 'checkbox-group' && item.required === true) {


                if (item.values.filter((i) => { return i.selected }).length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'date' && item.required) {

                if (item.value !== undefined && item.value !== null) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'number' && item.required) {

                if (item.value !== undefined && parseFloat(item.value.toString()) >= 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            } else if (item.type === 'number' && item.required === false) {
                if (item.value !== undefined) {
                    if (item.value === null || isNaN(item.value)) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) < 0) {
                        result = result + 1
                        item.error = 1
                    } else if (parseFloat(item.value.toString()) >= 0) {
                        item.error = 0
                    }
                }
            } else if (item.type === 'radio-group' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'select' && item.required) {

                if (item.values.filter((i) => { return i.selected }).length === 1) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'text' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'textarea' && item.required) {

                if (item.value !== undefined && item.value.trim().length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }
            else if (item.type === 'file' && item.required) {
                if (item.value !== undefined && item.value.length !== 0) {
                    result = result + 1
                    item.error = 0
                } else {
                    item.error = 1
                }
            }


        })

        return result === total
    }
    const makeEmpty = () => {
        let result = 0
        let data_ = JSON.parse(JSON.stringify(data.data1))

        data_.forEach((item) => {


            if (item.type === 'checkbox-group') {
                item.values.forEach((i) => {
                    i.selected = false
                })


            } else if (item.type === 'date') {

                item.value = null
            }
            else if (item.type === 'number') {

                item.result = 0
            }

            else if (item.type === 'radio-group' && item.required) {
                item.values.forEach((i) => {
                    i.selected = false
                })

            }
            else if (item.type === 'select') {

                item.values.forEach((i) => {
                    i.selected = false
                })
            }
            else if (item.type === 'text') {

                item.value = 0
            }
            else if (item.type === 'textarea') {

                item.value = 0
            } else if (item.type === 'file') {
                item['value'] = []
            }


        })


        return data_
    }


    const checkResponse_ = () => {
        console.log(data)
        if (data.data1.length === 0) {
            Swal.fire({
                position: "center",
                icon: "warning",
                title: "Data set is Empty, requires minimum 1 record to submit/draft",
                showConfirmButton: false,
                timer: 1500,
            });
            return false
        } else {

            return true
        }
    }
    const checkResponse_263 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.data.forEach((l) => {
                    numbers.push(Object.values(l)[0])
                })

            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const saveDCF_263 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_263()) {

            newObj['type'] = 2
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id
            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {

                        return 'Enter Remarks'
                    }
                }
            })
            if (isConfirmed) {

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''
                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }

                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }

                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",
                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const checkResponse_275 = () => {

        return Object.values(data.data1[0]).filter(i => (i >= 0 && i !== null)).length === 31
    }
    const saveDCF_275 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_275()) {

            newObj['type'] = 2
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id
            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {

                        return 'Enter Remarks'
                    }
                }
            })
            if (isConfirmed) {

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''
                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }

                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }

                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",
                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const saveDCF2 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (true) {

            newObj['type'] = 2
            newObj['reject'] = 0
            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id
            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {

                        return 'Enter Remarks'
                    }
                }
            })
            if (isConfirmed) {

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''
                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }

                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }

                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",
                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const saveDCF_262 = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_262()) {

            newObj['type'] = 2
            newObj['reject'] = 0

            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id
            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {
                        return 'Enter Remarks'
                    }
                }
            })
            if (isConfirmed) {

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''
                    // newObj['reviewed_by'] = selector.id
                    // newObj['reviewed_on'] = DateTime.utc()
                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }

                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }



                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }

    const saveDCF_ = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse_()) {

            newObj['type'] = 2
            newObj['reject'] = 0

            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {
                        return 'Enter Remarks'
                    }
                }
            })

            if (isConfirmed) {

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''
                    // newObj['reviewed_by'] = selector.id
                    // newObj['reviewed_on'] = DateTime.utc()
                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }
                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })

                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }



                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }
            }
        }
        forceUpdate()

    }
    const saveDCF = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        if (checkResponse()) {

            newObj['type'] = 2
            newObj['reject'] = 0

            newObj['last_modified_on'] = dt
            newObj['reviewer_modified_on'] = dt
            newObj['reviewer_modified_by'] = selector.id
            newObj['last_modified_by'] = selector.id

            let { value: return_remarks, isConfirmed } = await Swal.fire({
                html: `<label class="fs-16 fw-5">Please Enter Remarks If Any</label>`,
                input: 'textarea',

                inputValue: '',

                allowOutsideClick: false,
                showCancelButton: true,
                inputValidator: (value) => {
                    if ((response.return_remarks !== null && response.return_remarks.filter(i => i.user_type === 3).length !== 0) ? !value.trim() : false) {
                        return 'Enter Remarks'
                    }
                }
            })

            if (isConfirmed)

                if (response.id !== undefined) {
                    newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 2, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
                    let mail_remarks = ''

                    if (return_remarks) {
                        if (response.return_remarks === null) {
                            newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

                        } else {
                            let lt = response.return_remarks
                            lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                            newObj['return_remarks'] = lt
                        }

                        JSON.parse(JSON.stringify(newObj['return_remarks'])).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    } else if (response.return_remarks !== null) {
                        JSON.parse(JSON.stringify(response.return_remarks)).map((cmnt) => {

                            mail_remarks += `<p><strong>${getUser(cmnt.user_id)} (${DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy HH:mm')} hrs)</strong> : ${cmnt.remarks} </p>`

                        })
                    }


                    APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {


                        Swal.fire({
                            title: "Data Submitted Successfully",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.close()
                            }
                        })
                    }).catch((e) => {
                        Swal.fire({
                            title: "Something went wrong, try after some time. Contact admin if issue still persist",

                            confirmButtonText: 'Exit',
                            allowOutsideClick: false,
                        }).then((result) => {

                            if (result.isConfirmed) {
                                window.close()
                            }
                        })

                    })

                }

        }
        forceUpdate()

    }

    const reCall = async () => {
        let newObj = {}
        let dt = DateTime.utc()
        newObj['type'] = 1
        newObj['last_modified_on'] = dt
        newObj['last_modified_by'] = selector.id
        const { value: accept } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall DCF Submission</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this submission</div>`,

            confirmButtonColor: 'red',
            showCancelButton: true,

            confirmButtonText:
                'Revoke submission',

        })
        if (accept) {
            APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                Swal.fire({
                    title: "Data Submission has been revoked",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })
        }
    }
    const returnToReporter = async () => {
        let { value: return_remarks } = await Swal.fire({
            title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Alert</div>`,
            html: `<div style="overflow:auto;max-height:200px" >Please enter reason for returning submissions to reporter on this parameter(s)</div>`,
            input: 'textarea',
            inputValue: '',
            allowOutsideClick: false,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value.trim()) {
                    return 'Enter Remarks'
                }
            }
        })

        if (return_remarks.trim()) {
            let newObj = {}
            let dt = DateTime.utc()
            newObj['type'] = 0
            newObj['reject'] = 1
            newObj['last_modified_on'] = dt
            newObj['last_modified_by'] = selector.id
            newObj['logs'] = [{ user_id: selector.id, user_type: 2, type: 1, category: 1, created_on: dt, formId: id, remarks: return_remarks }, ...response.logs]
            if (response.return_remarks === null) {
                newObj['return_remarks'] = [{ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt }]

            } else {
                let lt = response.return_remarks
                lt.push({ remarks: return_remarks, user_type: 2, type: 1, user_id: selector.id, created_on: dt })
                newObj['return_remarks'] = lt
            }
            APIServices.patch(API.QN_Submission_Edit(response.id), newObj).then((res) => {

                Swal.fire({
                    title: "Data Submission Sent Back To Reporter For Correction",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })
            }).catch((e) => {
                Swal.fire({
                    title: "Something went wrong, try after some time. Contact admin if issue still persist",

                    confirmButtonText: 'Exit',
                    allowOutsideClick: false,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.close()
                    }
                })

            })

        }
    }
    const checkResponse_262 = () => {
        let numbers = []
        data.data1.forEach((i) => {
            i.type2.forEach((j) => {
                j.type3.forEach((k) => {
                    k.data.forEach((l) => {
                        numbers.push(Object.values(l)[0])
                    })
                })
            })
        })
        console.log(numbers, data.data1)
        console.log(numbers.filter((i) => { return i === null }).length === 0)
        return numbers.filter((i) => { return i === null }).length === 0
    }
    const getUser = (id) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === id)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    }

    const checkHardcoded = () => {
        console.log(hardcoded.dcf.includes(id))
        if (hardcoded.dcf.includes(id) || hardcoded.dcf2.includes(id)) {
            return true
        } else {
            return false
        }

    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }

    return (

        <div className="col-12 font-lato" style={{ margin: 10 }}>
            <StickyContainer>
                {(show && data.id !== undefined && response.id !== undefined) ?

                    <div className="col-12">
                        {(selector.id !== undefined) ?

                            <div >
                                <Sticky topOffset={0}>
                                    {({ style, isSticky }) => (<div style={{ ...style, top: 98, background: isSticky && 'white', zIndex: 100 }}>
                                        <div className="fs-20 fw-7 clr-gray-900">
                                            <h4><span className="mr-2">{'DCF ' + id}</span> {data.title}</h4>
                                        </div>

                                        {/* {data.comments.trim().length !== 0 &&
                                        <div className="p-card fw-5 fs-16" style={{ padding: 10, marginBottom: 20 }}>
                                            <span style={{ fontWeight: 'bold' }}>Note :&nbsp;</span> {data.comments}
                                        </div>} */}
                                        <div className="bg-white" style={{ padding: 24, borderBottom: '1px solid #E0E0E0' }} >
                                        <div className="grid justify-content-between col-12">
                                                <div className="col-4 fs-16" style={{ flexDirection: 'row' }}>         <span className="clr-gray-3 fw-4">Reporting period:&nbsp;</span> <span className="clr-gray-900 fw-7">{getRPTextFormat(response.reporting_period)}   </span>  </div>
                                                <div className="col-5 fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Coverage:&nbsp;</span> <span className="clr-gray-900 fw-7"> {getCoverageText(response, sitelist)} </span>  </div>

                                                <div className="col-3 fs-16" style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">{'Last Updated On'} :&nbsp;</span> <span className="clr-gray-900 fw-7">{DateTime.fromISO(response.last_modified_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy, HH:mm')}</span>  </div>
                                            </div>
                                            <div className="grid justify-content-between col-12">

                                                <div className="col-5 fs-16 " style={{ flexDirection: 'row' }}>          <span className="clr-gray-3 fw-4">Current Status :&nbsp;</span> <span className="clr-gray-900 fw-7"> {(response.type === 0 && response.reject === 1) ? 'Require Resubmission' : response.type === 0 ? 'Drafted' : (response.type === 1 && response.reject === 2) ? 'Re-Review' :  response.type === 1 ? 'Under Review' : response.type === 2 ? 'Under Approval' : response.type === 3 ? 'Approved' : 'NA'} </span>  </div>


                                            </div>
                                        </div>
                                    </div>)}
                                </Sticky>

                                {(data.data1.length !== 0 && !checkHardcoded()) ?
                                    <div>
                                        <div className="bg-white" style={{ borderRadius: 4 }}>

                                            <div style={{ padding: 24 }}>
                                                {data.data1.map((item, index) => {

                                                    return renderItems(item, index)
                                                })

                                                }
                                                 
                                            </div>
                                            <AttachmentComponent edit={0} documents={document} />
                                            <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                            {response !== undefined && response.return_remarks !== null && <div className="grid" style={{ padding: 24 }} >
                                                <label className="col-5 fw-7 fs-16">Comments</label>
                                                {/* <Editor className="text-area col-5" value={data.remarks} style={{ width: '100%', padding: 10, maxHeight: 350, height: 158, overflow: 'scroll' }} onTextChange={(e) => { console.log(e.htmlValue); data.remarks = e.htmlValue; forceUpdate() }} /> */}
                                                <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                    {response !== undefined && response.return_remarks !== null &&
                                                        response.return_remarks.map((cmnt) => {
                                                            return (
                                                                <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                    <div className="col-5">
                                                                        <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                        <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                    </div>
                                                                    <div className="col-5">
                                                                        {cmnt.remarks}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                    {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                </div>

                                            </div>}
                                        </div>
                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                            <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                <Button label='Close' className="ml-4" onClick={() => { window.close() }} text ></Button>




                                            </div>
                                        </div>

                                    </div>
                                    : hardcoded.dcf.includes(id) ?
                                        <div >
                                            <div className="bg-white" style={{ padding: 24 }}>
                                                <>
                                                    {

                                                        (id === '10') ?
                                                            <div>
                                                                <Fugitive_ allowedCategories={[]} dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                            </div>

                                                            :
                                                            (id === '11') ?
                                                                <div>
                                                                    <Stationary_Combustion_ allowedCategories={[]} dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                </div>
                                                                :
                                                                (id === '36') ?
                                                                    <div>
                                                                        < Business_Travel_ dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                    </div>

                                                                    :
                                                                    (id === '15') ?
                                                                        <div>
                                                                            <Mobile_Combustion_ dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                        </div>

                                                                        :
                                                                        (id === '16') ?
                                                                            <div>
                                                                                <Purchase_Goods_Services_ allowedCategories={[]} dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                            </div>
                                                                            : id === '188' ?
                                                                                <div>
                                                                                    <BP8EQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                </div>
                                                                                : id === '195' ?
                                                                                    <div>
                                                                                        <BP2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                    </div>
                                                                                    : id === '196' ?
                                                                                        <div>
                                                                                            <BP2LQ5 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                        </div>
                                                                                        : id === '245' ?
                                                                                            <div>
                                                                                                <GR2LQ1 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                            </div>
                                                                                            : id === '246' ?
                                                                                                <div>
                                                                                                    <GR2LQ2 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                </div>
                                                                                                : id === '247' ?
                                                                                                    <div>
                                                                                                        <GR2LQ3 data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                    </div>
                                                                                                    : id === '254' ?
                                                                                                        <div>
                                                                                                            <HotelStay dates={getMinMaxDates(response.reporting_period)} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                        </div>
                                                                                                        : id === '257' ?
                                                                                                            <div>
                                                                                                                <Electricity standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                            </div>
                                                                                                            : id === '282' ?
                                                                                                                <div>
                                                                                                                    <Capital_Goods allowedCategories={[]} dates={getMinMaxDates(getRPLuxon(params.state.reporting_period))} standard={std} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                </div>
                                                                                                                : id === '283' ?
                                                                                                                    <div>
                                                                                                                        <Scope3_Investments data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                    </div> :
                                                                                                                    id === '292' ?
                                                                                                                        <div>
                                                                                                                            <Upstream_Trans_Dist data={data.data1} standard={std} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                        </div> :
                                                                                                                        id === '293' ?
                                                                                                                            <div>
                                                                                                                                <Downstream_Trans_Dist data={data.data1} standard={std} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />

                                                                                                                            </div> :
                                                                                                                            'Contact Admin'



                                                    }</>
                                                    <AttachmentComponent edit={0} documents={document} />
                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                {response !== undefined && response.return_remarks !== null && <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                        {response !== undefined && response.return_remarks !== null &&
                                                            response.return_remarks.map((cmnt) => {
                                                                return (
                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                        <div className="col-5">
                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                        </div>
                                                                        <div className="col-5">
                                                                            {cmnt.remarks}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div style={{ padding: 24 }}>
                                                        {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                    </div>
                                                </div>}

                                            </div>

                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                <div className="flex justify-content-end " style={{ background: 'white', padding: 10 }}>
                                                    <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>



                                                </div>
                                            </div>

                                        </div>
                                        : id === '262' ?
                                            <div>
                                                <div className="bg-white" style={{ padding: 24 }}>
                                                    <Employee_Category data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <AttachmentComponent edit={0} documents={document} />
                                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                    {response !== undefined && response.return_remarks !== null && <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                        <label className="col-5 fw-7 fs-16">Comments</label>
                                                        <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                            {response !== undefined && response.return_remarks !== null &&
                                                                response.return_remarks.map((cmnt) => {
                                                                    return (
                                                                        <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                            <div className="col-5">
                                                                                <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                {cmnt.remarks}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>}
                                                    <div >
                                                        {/* <img src={require('../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                    </div>
                                                </div>
                                                <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>

                                                        <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                    </div>
                                                </div>
                                            </div> : id === '263' ?
                                                <div>
                                                <div className="bg-white" style={{ padding: 24 }}>
                                                    <Employee_Category_Diversity_STT isValidResponse={(e) => { console.log(e) }} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                    <AttachmentComponent edit={0} documents={document} />
                                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                    <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                        <label className="col-5 fw-7 fs-16">Comments</label>
                                                        <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                            {response !== undefined && response.return_remarks !== null &&
                                                                response.return_remarks.map((cmnt) => {
                                                                    return (
                                                                        <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                            <div className="col-5">
                                                                                <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                            </div>
                                                                            <div className="col-5">
                                                                                {cmnt.remarks}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div >
                                                        {/* <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                    </div>
                                                </div>
                                                <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                        <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                    </div>
                                                </div>
                                            </div> :
                                                id === '275' ?
                                                    <div>
                                                        <div className="bg-white" style={{ padding: 24 }}>
                                                            <Employee_Category_Diversity_STT isValidResponse={(e) => { console.log(e) }} data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                            <AttachmentComponent edit={0} documents={document} />
                                                            <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                            <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                                <label className="col-5 fw-7 fs-16">Comments</label>
                                                                <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                                    {response !== undefined && response.return_remarks !== null &&
                                                                        response.return_remarks.map((cmnt) => {
                                                                            return (
                                                                                <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                                    <div className="col-5">
                                                                                        <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                        <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                                    </div>
                                                                                    <div className="col-5">
                                                                                        {cmnt.remarks}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div >
                                                                {/* <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                            </div>
                                                        </div>
                                                        <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                            <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                                <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : id === '277' ?
                                                        <div>
                                                            <div className="bg-white" style={{ padding: 24 }}>
                                                                <Total_No_of_New_Employee_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                <AttachmentComponent edit={0} documents={document} />
                                                                <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                                <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                                    <label className="col-5 fw-7 fs-16">Comments</label>
                                                                    <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                                        {response !== undefined && response.return_remarks !== null &&
                                                                            response.return_remarks.map((cmnt) => {
                                                                                return (
                                                                                    <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                                        <div className="col-5">
                                                                                            <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                            <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                                        </div>
                                                                                        <div className="col-5">
                                                                                            {cmnt.remarks}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div >
                                                                    {/* <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                                </div>
                                                            </div>
                                                            <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                                <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                                    <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        : id === '278' ?
                                                            <div>
                                                                <div className="bg-white" style={{ padding: 24 }}>
                                                                    <Total_No_of_Employee_Left_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                    <AttachmentComponent edit={0} documents={document} />
                                                                    <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                                    <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                                        <label className="col-5 fw-7 fs-16">Comments</label>
                                                                        <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                                            {response !== undefined && response.return_remarks !== null &&
                                                                                response.return_remarks.map((cmnt) => {
                                                                                    return (
                                                                                        <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                                            <div className="col-5">
                                                                                                <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                                <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                {cmnt.remarks}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div >
                                                                        {/* <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} /> */}
                                                                    </div>
                                                                </div>
                                                                <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                                    <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                                        <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : id === '284' ?
                                                                <div>
                                                                    <div className="bg-white" style={{ padding: 24 }}>
                                                                        <Total_No_of_Employee_Hire_TurnOver_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                        <AttachmentComponent edit={0} documents={document} />
                                                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                                        <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                                                {response !== undefined && response.return_remarks !== null &&
                                                                                    response.return_remarks.map((cmnt) => {
                                                                                        return (
                                                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                                                <div className="col-5">
                                                                                                    <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                                                </div>
                                                                                                <div className="col-5">
                                                                                                    {cmnt.remarks}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {/* <div >
                                                                            <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                                        </div> */}
                                                                    </div>
                                                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                                            <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                                        </div>
                                                                    </div>
                                                                </div> : id === '295' &&
                                                                <div>
                                                                    <div className="bg-white" style={{ padding: 24 }}>
                                                                        <Employee_Training_Hours_STT data={data.data1} edit={0} setData={() => { forceUpdate() }} getData={(e) => { data.data1 = e; }} />
                                                                        <AttachmentComponent edit={0} documents={document} />
                                                                        <div style={{ borderTop: '1px solid #E0E0E0' }} />
                                                                        <div className="bg-white grid" style={{ paddingTop: 24 }} >
                                                                            <label className="col-5 fw-7 fs-16">Comments</label>
                                                                            <div className="col-12" style={{ maxHeight: 300, overflowY: 'scroll', overflow: 'auto' }}>
                                                                                {response !== undefined && response.return_remarks !== null &&
                                                                                    response.return_remarks.map((cmnt) => {
                                                                                        return (
                                                                                            <div className="col-12 grid" style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                                                                                <div className="col-5">
                                                                                                    <div>   {cmnt.user_id === selector.id ? 'You' : getUser(cmnt.user_id)}</div>
                                                                                                    <div className="mt-2" >     {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                                                                                </div>
                                                                                                <div className="col-5">
                                                                                                    {cmnt.remarks}
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {/* <div >
                                                                            <img src={require('../../../../assets/dummy/user_placeholder.jpeg').default} style={{ width: '100%' }} />
                                                                        </div> */}
                                                                    </div>
                                                                    <div style={{ position: 'sticky', bottom: 0, zIndex: 100 }}>
                                                                        <div className="flex justify-content-end" style={{ background: 'white', padding: 10 }}>
                                                                            <Button className="ml-4" label='Close' onClick={() => { window.close() }} text></Button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                }




                            </div>

                            :
                            <div className="col-12 card">Form not found</div>
                            // <div className="col-12 card">You have no rights to access this page </div>

                        }
                    </div> :
                    <div style={{
                        justifyContent: 'center',
                        display: 'flex',
                        marginTop: '50px'
                    }}>

                        <i className="pi pi-spin pi-spinner" style={{ fontSize: 50 }}></i>
                    </div>



                }
            </StickyContainer>
        </div>

    );

};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(OverallQnSubmissionPreview, comparisonFn);
