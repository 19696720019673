export const bt_road_category =
    [
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Mini",
            "id": 1
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Supermini",
            "id": 2
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Lower medium",
            "id": 3
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Upper medium",
            "id": 4
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Executive",
            "id": 5
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Luxury",
            "id": 6
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Sports",
            "id": 7
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Dual purpose 4X4",
            "id": 8
        },
        {
            "vehicle_type": "Cars (by market segment)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "MPV",
            "id": 9
        },
        {
            "vehicle_type": "Cars (by size)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Hybrid' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }], fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Hybrid' }, { name: 'CNG' }, { name: 'LPG' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Small car",
            "id": 10
        },
        {
            "vehicle_type": "Cars (by size)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Hybrid' }, { name: 'CNG' }, { name: 'LPG' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Medium car",
            "id": 11
        },
        {
            "vehicle_type": "Cars (by size)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Hybrid' }, { name: 'CNG' }, { name: 'LPG' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Large car",
            "id": 12
        },
        {
            "vehicle_type": "Cars (by size)", fuelcat: [{ name: 'Diesel' }, { name: 'Petrol' }, { name: 'Hybrid' }, { name: 'CNG' }, { name: 'LPG' }, { name: 'Unknown' }, { name: 'Plug-in Hybrid Electric Vehicle' }, { name: 'Battery Electric Vehicle' }],
            "subtype": "Average car",
            "id": 13
        },
        {
            "vehicle_type": "Taxis",
            "subtype": "Taxis", fuelcat: [],
            "id": 14
        },
        {
            "vehicle_type": "Bus",
            "subtype": "Bus", fuelcat: [],
            "id": 15
        },
        {
            "vehicle_type": "Motorbike",
            "subtype": "Small", fuelcat: [],
            "id": 16
        }, {
            "vehicle_type": "Motorbike",
            "subtype": "Medium", fuelcat: [],
            "id": 17
        }, {
            "vehicle_type": "Motorbike",
            "subtype": "Large", fuelcat: [],
            "id": 18
        }, {
            "vehicle_type": "Motorbike",
            "subtype": "Average", fuelcat: [],
            "id": 19
        }
    ]
