export  const fuelcategory = [
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Butane",
        "unit": "tonnes",
        "total": 3033.32,
        "co2": 3029.26,
        "ch4": 2.25,
        "n2o": 1.8
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Butane",
        "unit": "litres",
        "total": 1.75,
        "co2": 1.74,
        "ch4": 0,
        "n2o": 0
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Butane",
        "unit": "kWh (Net CV)",
        "total": 0.24,
        "co2": 0.24,
        "ch4": 0,
        "n2o": 0
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Butane",
        "unit": "kWh (Gross CV)",
        "total": 0.22,
        "co2": 0.22,
        "ch4": 0,
        "n2o": 0
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "CNG",
        "unit": "tonnes",
        "total": 2539.25,
        "co2": 2534.47,
        "ch4": 3.44,
        "n2o": 1.34
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "CNG",
        "unit": "litres",
        "total": 0.44,
        "co2": 0.44353,
        "ch4": 0.0006,
        "n2o": 0.00023
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "CNG",
        "unit": "kWh (Net CV)",
        "total": 0.2,
        "co2": 0.20188,
        "ch4": 0.00028,
        "n2o": 0.00011
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "CNG",
        "unit": "kWh (Gross CV)",
        "total": 0.18,
        "co2": 0.18219,
        "ch4": 0.00025,
        "n2o": 0.0001
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LNG",
        "unit": "tonnes",
        "total": 2559.17,
        "co2": 2554.39,
        "ch4": 3.44,
        "n2o": 1.34
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LNG",
        "unit": "litres",
        "total": 1.16,
        "co2": 1.15583,
        "ch4": 0.00156,
        "n2o": 0.00061
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LNG",
        "unit": "kWh (Net CV)",
        "total": 0.2,
        "co2": 0.20347,
        "ch4": 0.00028,
        "n2o": 0.00011
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LNG",
        "unit": "kWh (Gross CV)",
        "total": 0.18,
        "co2": 0.18362,
        "ch4": 0.00025,
        "n2o": 0.0001
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LPG",
        "unit": "tonnes",
        "total": 2939.29,
        "co2": 2935.18,
        "ch4": 2.28,
        "n2o": 1.83
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LPG",
        "unit": "litres",
        "total": 1.56,
        "co2": 1.55491,
        "ch4": 0.00121,
        "n2o": 0.00097
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LPG",
        "unit": "kWh (Net CV)",
        "total": 0.23,
        "co2": 0.22999,
        "ch4": 0.00018,
        "n2o": 0.00014
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "LPG",
        "unit": "kWh (Gross CV)",
        "total": 0.21,
        "co2": 0.21419,
        "ch4": 0.00017,
        "n2o": 0.00013
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas",
        "unit": "tonnes",
        "total": 2539.25,
        "co2": 2534.47,
        "ch4": 3.44,
        "n2o": 1.34
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas",
        "unit": "cubic metres",
        "total": 2.02,
        "co2": 2.01193,
        "ch4": 0.00274,
        "n2o": 0.00107
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas",
        "unit": "kWh (Net CV)",
        "total": 0.2,
        "co2": 0.20188,
        "ch4": 0.00028,
        "n2o": 0.00011
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas",
        "unit": "kWh (Gross CV)",
        "total": 0.18,
        "co2": 0.18219,
        "ch4": 0.00025,
        "n2o": 0.0001
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas (100% mineral blend)",
        "unit": "tonnes",
        "total": 2559.17,
        "co2": 2554.39,
        "ch4": 3.44,
        "n2o": 1.34
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas (100% mineral blend)",
        "unit": "cubic metres",
        "total": 2.03,
        "co2": 2.02774,
        "ch4": 0.00274,
        "n2o": 0.00107
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas (100% mineral blend)",
        "unit": "kWh (Net CV)",
        "total": 0.2,
        "co2": 0.20347,
        "ch4": 0.00028,
        "n2o": 0.00011
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Natural gas (100% mineral blend)",
        "unit": "kWh (Gross CV)",
        "total": 0.18,
        "co2": 0.18362,
        "ch4": 0.00025,
        "n2o": 0.0001
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Other petroleum gas",
        "unit": "tonnes",
        "total": 2578.25,
        "co2": 2575.7,
        "ch4": 1.17,
        "n2o": 1.39
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Other petroleum gas",
        "unit": "litres",
        "total": 0.94,
        "co2": 0.94348,
        "ch4": 0.00043,
        "n2o": 0.00051
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Other petroleum gas",
        "unit": "kWh (Net CV)",
        "total": 0.2,
        "co2": 0.19897,
        "ch4": 0.00009,
        "n2o": 0.00011
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Other petroleum gas",
        "unit": "kWh (Gross CV)",
        "total": 0.18,
        "co2": 0.18305,
        "ch4": 0.00008,
        "n2o": 0.0001
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Propane",
        "unit": "tonnes",
        "total": 2997.55,
        "co2": 2993.4,
        "ch4": 2.31,
        "n2o": 1.85
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Propane",
        "unit": "litres",
        "total": 1.54,
        "co2": 1.5414,
        "ch4": 0.00119,
        "n2o": 0.00095
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Propane",
        "unit": "kWh (Net CV)",
        "total": 0.23,
        "co2": 0.23225,
        "ch4": 0.00018,
        "n2o": 0.00014
    },
    {
        "fuel_type": "Gaseous Fuels",
        "fuel": "Propane",
        "unit": "kWh (Gross CV)",
        "total": 0.21,
        "co2": 0.21381,
        "ch4": 0.00016,
        "n2o": 0.00013
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation spirit",
        "unit": "tonnes",
        "total": 3192.76,
        "co2": 3127.67,
        "ch4": 35.29,
        "n2o": 29.8
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation spirit",
        "unit": "litres",
        "total": 2.33,
        "co2": 2.28297,
        "ch4": 0.02576,
        "n2o": 0.02175
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation spirit",
        "unit": "kWh (Net CV)",
        "total": 0.26,
        "co2": 0.25135,
        "ch4": 0.00284,
        "n2o": 0.00239
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation spirit",
        "unit": "kWh (Gross CV)",
        "total": 0.24,
        "co2": 0.23878,
        "ch4": 0.00269,
        "n2o": 0.00228
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation turbine fuel",
        "unit": "tonnes",
        "total": 3181.43,
        "co2": 3149.67,
        "ch4": 1.96,
        "n2o": 29.8
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation turbine fuel",
        "unit": "litres",
        "total": 2.55,
        "co2": 2.51973,
        "ch4": 0.00157,
        "n2o": 0.02384
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation turbine fuel",
        "unit": "kWh (Net CV)",
        "total": 0.26,
        "co2": 0.25826,
        "ch4": 0.00016,
        "n2o": 0.00244
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Aviation turbine fuel",
        "unit": "kWh (Gross CV)",
        "total": 0.25,
        "co2": 0.24535,
        "ch4": 0.00015,
        "n2o": 0.00232
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Burning oil",
        "unit": "tonnes",
        "total": 3165.01,
        "co2": 3149.67,
        "ch4": 7.5,
        "n2o": 7.84
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Burning oil",
        "unit": "litres",
        "total": 2.54,
        "co2": 2.52782,
        "ch4": 0.00602,
        "n2o": 0.00629
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Burning oil",
        "unit": "kWh (Net CV)",
        "total": 0.26,
        "co2": 0.25849,
        "ch4": 0.00062,
        "n2o": 0.00064
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Burning oil",
        "unit": "kWh (Gross CV)",
        "total": 0.25,
        "co2": 0.24557,
        "ch4": 0.00059,
        "n2o": 0.00061
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (average biofuel blend)",
        "unit": "tonnes",
        "total": 3032.89,
        "co2": 2988.85,
        "ch4": 0.31,
        "n2o": 43.73
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (average biofuel blend)",
        "unit": "litres",
        "total": 2.56,
        "co2": 2.52058,
        "ch4": 0.00026,
        "n2o": 0.037
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (average biofuel blend)",
        "unit": "kWh (Net CV)",
        "total": 0.26,
        "co2": 0.25257,
        "ch4": 0.00003,
        "n2o": 0.00371
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (average biofuel blend)",
        "unit": "kWh (Gross CV)",
        "total": 0.24,
        "co2": 0.23764,
        "ch4": 0.00002,
        "n2o": 0.00349
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (100% mineral diesel)",
        "unit": "tonnes",
        "total": 3208.76,
        "co2": 3164.33,
        "ch4": 0.31,
        "n2o": 44.12
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (100% mineral diesel)",
        "unit": "litres",
        "total": 2.7,
        "co2": 2.66134,
        "ch4": 0.00026,
        "n2o": 0.0372
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (100% mineral diesel)",
        "unit": "kWh (Net CV)",
        "total": 0.27,
        "co2": 0.26565,
        "ch4": 0.00003,
        "n2o": 0.00371
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Diesel (100% mineral diesel)",
        "unit": "kWh (Gross CV)",
        "total": 0.25,
        "co2": 0.24971,
        "ch4": 0.00002,
        "n2o": 0.00348
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Fuel oil",
        "unit": "tonnes",
        "total": 3229.2,
        "co2": 3216.38,
        "ch4": 4.81,
        "n2o": 8.01
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Fuel oil",
        "unit": "litres",
        "total": 3.18,
        "co2": 3.16262,
        "ch4": 0.00473,
        "n2o": 0.00788
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Fuel oil",
        "unit": "kWh (Net CV)",
        "total": 0.29,
        "co2": 0.28413,
        "ch4": 0.00042,
        "n2o": 0.00071
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Fuel oil",
        "unit": "kWh (Gross CV)",
        "total": 0.27,
        "co2": 0.26709,
        "ch4": 0.0004,
        "n2o": 0.00067
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Gas oil",
        "unit": "tonnes",
        "total": 3230.28,
        "co2": 3190,
        "ch4": 3.29,
        "n2o": 36.99
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Gas oil",
        "unit": "litres",
        "total": 2.76,
        "co2": 2.72417,
        "ch4": 0.00281,
        "n2o": 0.03159
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Gas oil",
        "unit": "kWh (Net CV)",
        "total": 0.27,
        "co2": 0.26978,
        "ch4": 0.00028,
        "n2o": 0.00313
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Gas oil",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.25359,
        "ch4": 0.00026,
        "n2o": 0.00294
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Lubricants",
        "unit": "tonnes",
        "total": 3181.44,
        "co2": 3171.09,
        "ch4": 3.06,
        "n2o": 7.29
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Lubricants",
        "unit": "litres",
        "total": 2.75,
        "co2": 2.74,
        "ch4": 0,
        "n2o": 0.01
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Lubricants",
        "unit": "kWh (Net CV)",
        "total": 0.28,
        "co2": 0.28013,
        "ch4": 0.00027,
        "n2o": 0.00064
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Lubricants",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.26332,
        "ch4": 0.00025,
        "n2o": 0.00061
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Naphtha",
        "unit": "tonnes",
        "total": 3142.87,
        "co2": 3131.33,
        "ch4": 3.41,
        "n2o": 8.13
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Naphtha",
        "unit": "litres",
        "total": 2.12,
        "co2": 2.11,
        "ch4": 0,
        "n2o": 0.01
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Naphtha",
        "unit": "kWh (Net CV)",
        "total": 0.25,
        "co2": 0.24804,
        "ch4": 0.00027,
        "n2o": 0.00064
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Naphtha",
        "unit": "kWh (Gross CV)",
        "total": 0.24,
        "co2": 0.23564,
        "ch4": 0.00026,
        "n2o": 0.00061
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (average biofuel blend)",
        "unit": "tonnes",
        "total": 2903.08,
        "co2": 2884.53,
        "ch4": 9.68,
        "n2o": 8.87
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (average biofuel blend)",
        "unit": "litres",
        "total": 2.16,
        "co2": 2.14805,
        "ch4": 0.0072,
        "n2o": 0.0066
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (average biofuel blend)",
        "unit": "kWh (Net CV)",
        "total": 0.24,
        "co2": 0.23808,
        "ch4": 0.0008,
        "n2o": 0.00073
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (average biofuel blend)",
        "unit": "kWh (Gross CV)",
        "total": 0.23,
        "co2": 0.22575,
        "ch4": 0.00075,
        "n2o": 0.00069
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (100% mineral petrol)",
        "unit": "tonnes",
        "total": 3153.9,
        "co2": 3135,
        "ch4": 9.86,
        "n2o": 9.04
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (100% mineral petrol)",
        "unit": "litres",
        "total": 2.34,
        "co2": 2.32567,
        "ch4": 0.00732,
        "n2o": 0.00671
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (100% mineral petrol)",
        "unit": "kWh (Net CV)",
        "total": 0.25,
        "co2": 0.25275,
        "ch4": 0.0008,
        "n2o": 0.00073
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Petrol (100% mineral petrol)",
        "unit": "kWh (Gross CV)",
        "total": 0.24,
        "co2": 0.24011,
        "ch4": 0.00076,
        "n2o": 0.00069
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - residual oil",
        "unit": "tonnes",
        "total": 3229.2,
        "co2": 3216.38,
        "ch4": 4.81,
        "n2o": 8.01
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - residual oil",
        "unit": "litres",
        "total": 3.18,
        "co2": 3.16262,
        "ch4": 0.00473,
        "n2o": 0.00788
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - residual oil",
        "unit": "kWh (Net CV)",
        "total": 0.29,
        "co2": 0.28413,
        "ch4": 0.00042,
        "n2o": 0.00071
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - residual oil",
        "unit": "kWh (Gross CV)",
        "total": 0.27,
        "co2": 0.26709,
        "ch4": 0.0004,
        "n2o": 0.00067
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - distillate oil",
        "unit": "tonnes",
        "total": 3230.28,
        "co2": 3190,
        "ch4": 3.29,
        "n2o": 36.99
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - distillate oil",
        "unit": "litres",
        "total": 2.76,
        "co2": 2.72417,
        "ch4": 0.00281,
        "n2o": 0.03159
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - distillate oil",
        "unit": "kWh (Net CV)",
        "total": 0.27,
        "co2": 0.26978,
        "ch4": 0.00028,
        "n2o": 0.00313
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Processed fuel oils - distillate oil",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.25359,
        "ch4": 0.00026,
        "n2o": 0.00294
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Refinery miscellaneous",
        "unit": "tonnes",
        "total": 2944.81,
        "co2": 2933.33,
        "ch4": 3.39,
        "n2o": 8.09
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Refinery miscellaneous",
        "unit": "litres"
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Refinery miscellaneous",
        "unit": "kWh (Net CV)",
        "total": 0.26,
        "co2": 0.25864,
        "ch4": 0.0003,
        "n2o": 0.00071
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Refinery miscellaneous",
        "unit": "kWh (Gross CV)",
        "total": 0.25,
        "co2": 0.24571,
        "ch4": 0.00028,
        "n2o": 0.00068
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Waste oils",
        "unit": "tonnes",
        "total": 3224.57,
        "co2": 3171.09,
        "ch4": 3.17,
        "n2o": 50.31
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Waste oils",
        "unit": "litres",
        "total": 2.75,
        "co2": 2.71,
        "ch4": 0,
        "n2o": 0.04
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Waste oils",
        "unit": "kWh (Net CV)",
        "total": 0.28,
        "co2": 0.27047,
        "ch4": 0.00027,
        "n2o": 0.00429
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Waste oils",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.25256,
        "ch4": 0.00025,
        "n2o": 0.00401
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine gas oil",
        "unit": "tonnes",
        "total": 3249.99,
        "co2": 3205.99,
        "ch4": 0.81,
        "n2o": 43.19
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine gas oil",
        "unit": "litres",
        "total": 2.78,
        "co2": 2.73782,
        "ch4": 0.00069,
        "n2o": 0.03688
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine gas oil",
        "unit": "kWh (Net CV)",
        "total": 0.27,
        "co2": 0.27113,
        "ch4": 0.00007,
        "n2o": 0.00365
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine gas oil",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.25486,
        "ch4": 0.00006,
        "n2o": 0.00343
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine fuel oil",
        "unit": "tonnes",
        "total": 3159.5,
        "co2": 3113.99,
        "ch4": 1.27,
        "n2o": 44.24
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine fuel oil",
        "unit": "litres",
        "total": 3.11,
        "co2": 3.06194,
        "ch4": 0.00125,
        "n2o": 0.0435
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine fuel oil",
        "unit": "kWh (Net CV)",
        "total": 0.28,
        "co2": 0.27509,
        "ch4": 0.00011,
        "n2o": 0.00391
    },
    {
        "fuel_type": "Liquid Fuels",
        "fuel": "Marine fuel oil",
        "unit": "kWh (Gross CV)",
        "total": 0.26,
        "co2": 0.25858,
        "ch4": 0.00011,
        "n2o": 0.00367
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (industrial)",
        "unit": "tonnes",
        "total": 2411.43,
        "co2": 2385.57,
        "ch4": 6.82,
        "n2o": 19.04
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (industrial)",
        "unit": "kWh (Net CV)",
        "total": 0.34,
        "co2": 0.33805,
        "ch4": 0.00097,
        "n2o": 0.0027
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (industrial)",
        "unit": "kWh (Gross CV)",
        "total": 0.32,
        "co2": 0.32115,
        "ch4": 0.00092,
        "n2o": 0.00256
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation)",
        "unit": "tonnes",
        "total": 2270.45,
        "co2": 2257.23,
        "ch4": 0.6,
        "n2o": 12.62
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation)",
        "unit": "kWh (Net CV)",
        "total": 0.34,
        "co2": 0.33626,
        "ch4": 0.00009,
        "n2o": 0.00189
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation)",
        "unit": "kWh (Gross CV)",
        "total": 0.32,
        "co2": 0.31945,
        "ch4": 0.00009,
        "n2o": 0.00179
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (domestic)",
        "unit": "tonnes",
        "total": 2883.26,
        "co2": 2632,
        "ch4": 214.6,
        "n2o": 36.66
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (domestic)",
        "unit": "kWh (Net CV)",
        "total": 0.36,
        "co2": 0.33115,
        "ch4": 0.027,
        "n2o": 0.00461
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (domestic)",
        "unit": "kWh (Gross CV)",
        "total": 0.34,
        "co2": 0.31459,
        "ch4": 0.02565,
        "n2o": 0.00438
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coking coal",
        "unit": "tonnes",
        "total": 3165.24,
        "co2": 3144.16,
        "ch4": 7.56,
        "n2o": 13.52
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coking coal",
        "unit": "kWh (Net CV)",
        "total": 0.38,
        "co2": 0.37431,
        "ch4": 0.0009,
        "n2o": 0.00161
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coking coal",
        "unit": "kWh (Gross CV)",
        "total": 0.36,
        "co2": 0.35559,
        "ch4": 0.00085,
        "n2o": 0.00153
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Petroleum coke",
        "unit": "tonnes",
        "total": 3386.87,
        "co2": 3377.05,
        "ch4": 3.42,
        "n2o": 6.4
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Petroleum coke",
        "unit": "kWh (Net CV)",
        "total": 0.36,
        "co2": 0.35786,
        "ch4": 0.00036,
        "n2o": 0.00068
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Petroleum coke",
        "unit": "kWh (Gross CV)",
        "total": 0.34,
        "co2": 0.33997,
        "ch4": 0.00034,
        "n2o": 0.00064
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation - home produced coal only)",
        "unit": "tonnes",
        "total": 2266.9,
        "co2": 2253.7,
        "ch4": 0.6,
        "n2o": 12.6
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation - home produced coal only)",
        "unit": "kWh (Net CV)",
        "total": 0.34,
        "co2": 0.33626,
        "ch4": 0.00009,
        "n2o": 0.00189
    },
    {
        "fuel_type": "Solid Fuels",
        "fuel": "Coal (electricity generation - home produced coal only)",
        "unit": "kWh (Gross CV)",
        "total": 0.32,
        "co2": 0.31945,
        "ch4": 0.00009,
        "n2o": 0.00179
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bioethanol",
        "unit": "litres",
        "total": 0.00901
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bioethanol",
        "unit": "GJ",
        "total": 0.42339
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bioethanol",
        "unit": "kg",
        "total": 0.01135
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME",
        "unit": "litres",
        "total": 0.16751
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME",
        "unit": "GJ",
        "total": 5.05961
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME",
        "unit": "kg",
        "total": 0.18822
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (compressed)",
        "unit": "litres"
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (compressed)",
        "unit": "GJ",
        "total": 0.10625
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (compressed)",
        "unit": "kg",
        "total": 0.00521
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from used cooking oil)",
        "unit": "litres",
        "total": 0.16751
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from used cooking oil)",
        "unit": "GJ",
        "total": 5.05961
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from used cooking oil)",
        "unit": "kg",
        "total": 0.18822
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from tallow)",
        "unit": "litres",
        "total": 0.16751
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from tallow)",
        "unit": "GJ",
        "total": 5.05961
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel ME (from tallow)",
        "unit": "kg",
        "total": 0.18822
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel HVO",
        "unit": "litres",
        "total": 0.03558
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel HVO",
        "unit": "GJ",
        "total": 1.03677
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biodiesel HVO",
        "unit": "kg",
        "total": 0.04562
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biopropane",
        "unit": "litres",
        "total": 0.00214
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biopropane",
        "unit": "GJ",
        "total": 0.08952
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biopropane",
        "unit": "kg",
        "total": 0.00415
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bio Petrol",
        "unit": "litres",
        "total": 0.01402
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bio Petrol",
        "unit": "GJ",
        "total": 0.42339
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Bio Petrol",
        "unit": "kg",
        "total": 0.01891
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Renewable petrol",
        "unit": "litres",
        "total": 0.01059
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Renewable petrol",
        "unit": "GJ",
        "total": 0.42339
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Renewable petrol",
        "unit": "kg",
        "total": 0.01188
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Off road biodiesel",
        "unit": "litres",
        "total": 0.16751
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Off road biodiesel",
        "unit": "GJ",
        "total": 5.05961
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Off road biodiesel",
        "unit": "kg",
        "total": 0.18822
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (liquified)",
        "unit": "litres"
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (liquified)",
        "unit": "GJ",
        "total": 0.10625
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Biomethane (liquified)",
        "unit": "kg",
        "total": 0.00521
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Methanol (bio)",
        "unit": "litres",
        "total": 0.00676
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Methanol (bio)",
        "unit": "GJ",
        "total": 0.42339
    },
    {
        "fuel_type": "Biofuel",
        "fuel": "Methanol (bio)",
        "unit": "kg",
        "total": 0.00851
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood logs",
        "unit": "tonnes",
        "total": 43.03576
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood logs",
        "unit": "kWh",
        "total": 0.01053
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood chips",
        "unit": "tonnes",
        "total": 39.78833
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood chips",
        "unit": "kWh",
        "total": 0.01053
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood pellets",
        "unit": "tonnes",
        "total": 50.55459
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Wood pellets",
        "unit": "kWh",
        "total": 0.01053
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Grass/straw",
        "unit": "tonnes",
        "total": 48.04825
    },
    {
        "fuel_type": "Biomass",
        "fuel": "Grass/straw",
        "unit": "kWh",
        "total": 0.01292
    },
    {
        "fuel_type": "Biogas",
        "fuel": "Biogas",
        "unit": "tonnes",
        "total": 1.21919
    },
    {
        "fuel_type": "Biogas",
        "fuel": "Biogas",
        "unit": "kWh",
        "total": 0.00022
    },
    {
        "fuel_type": "Biogas",
        "fuel": "Landfill gas",
        "unit": "tonnes",
        "total": 0.68844
    },
    {
        "fuel_type": "Biogas",
        "fuel": "Landfill gas",
        "unit": "kWh",
        "total": 0.0002
    }
]